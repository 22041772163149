<template>
  <Spinner v-if="isLoading"></Spinner>
  <div class="mt-5 p-5" v-else>
    <div class="d-flex justify-content-end">
      <span class="text-white text-center mr-2">BGP SERVER STATUS</span>
      <span v-if="bgpStatus" class="online"></span>
      <span v-else class="offline"></span>
    </div>
    <h1 class="text-center text-white">MANUAL RULES</h1>
    <b-button variant="primary" class="mr-2" size="sm" :disabled="isLoading" :to="'/flowspec/create'">
      <font-awesome-icon icon="plus" class="text-white" /> Add
    </b-button>
    <b-button variant="primary" class="ml-2" size="sm" @click="viewNeighhours">
      <font-awesome-icon icon="eye" class="text-white" /> View Neighbor
    </b-button>
    <div class="d-flex justify-content-end w-100 mx-3 mb-5">
      <h5 class="p-0 my-3 mx-3">
        <font-awesome-icon icon="search" class="text-white" />
      </h5>
      <b-nav pills>
        <b-nav-form class="mr-2">
          <b-form-input
            :placeholder="'Detail search'"
            v-model="q"
            type="search"
            @input="handleSearchChange"
            class="mr-1"
          ></b-form-input>
        </b-nav-form>
      </b-nav>
    </div>
    <b-row class="rules rules-table" v-if="total > 0">
      <b-table
        id="rules-table"
        :items="specRules"
        :per-page="perPage"
        :current-page="currentPage"
        :fields="fields"
        ref="rules-table"
        hover
        dark
        class="small nowrap"
      >
        <template #cell(actions)="data">
          <b-dropdown
            id="dropdown-dropleft"
            dropleft
            variant="primary"
            text="Actions"
            class="sm-1"
            size="sm"
          >
            <b-dropdown-item href="#">
              <b-nav-form class="">
                <span class="d-flex align-items-center me-1"
                  >Rate <br />Limit<br />(Mbps)</span
                >
                <b-form-input
                  :placeholder="'Detail search'"
                  v-model="data.item.rate_limit"
                  type="text"
                  @input="handleRateLimitChange(data.item)"
                ></b-form-input>
              </b-nav-form>
            </b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item href="#">
              <div class="d-flex justify-content-center">
                <b-button
                  @click.prevent="unblock(data.item)"
                  variant="primary"
                  size="sm"
                  class="ms-1"
                  v-if="data.item.is_blocked"
                >
                  {{ "UnBlock" }}
                </b-button>
                <b-button
                  @click.prevent="block(data.item)"
                  variant="primary"
                  size="sm"
                  class="ms-1"
                  v-else
                >
                  {{ "Block" }}
                </b-button>
              </div>
            </b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item>
              <div class="d-flex justify-content-between">
                <b-button
                  variant="primary"
                  class="mr-2"
                  size="sm"
                  :to="'/flowspec/edit/' + data.item.id"
                >
                  <font-awesome-icon icon="pencil" />
                </b-button>
                <b-button
                  @click.prevent="showDetail(data)"
                  variant="primary"
                  class="mr-1"
                  size="sm"
                >
                  <font-awesome-icon icon="eye" />
                </b-button>
                <b-button
                  @click.prevent="del(data.item)"
                  variant="danger"
                  size="sm"
                  class="ms-1"
                >
                  <font-awesome-icon icon="trash" />
                </b-button>
              </div>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <b-row class="text-center m-3 d-block">
        <b-pagination
          v-model="currentPage"
          align="center"
          :total-rows="total"
          :per-page="perPage"
          aria-controls="rules-table"
        ></b-pagination>
      </b-row>
    </b-row>
    <b-row v-else>
      <p class="text-center text-white">No rules</p>
    </b-row>
    <b-modal
      v-model="showModal"
      hide-backdrop
      content-class="shadow"
      title="Rules"
    >
      <b-card v-if="isWholeBGP">
        <div v-for="(item, index) in modalData" :key="index">
          <h5 class="text-center">Apply Policy</h5>
          <b-badge pill variant="primary">
            <span>Export Policy:</span>
            <b-badge>{{ item.apply_policy.export_policy.direction }}</b-badge>
          </b-badge>
          <b-badge pill variant="primary">
            <span>Import Policy:</span>
            <b-badge>{{ item.apply_policy.import_policy.direction }}</b-badge>
          </b-badge>
          <h5 class="text-center">Conf</h5>
          <b-badge pill variant="primary">
            <span>PeerAsn:</span>
            <b-badge>{{ item.conf.peer_asn }}</b-badge>
          </b-badge>
          <b-badge pill variant="primary">
            <span>Neighbor Address:</span>
            <b-badge>{{ item.conf.neighbor_address }}</b-badge>
          </b-badge>
          <h5 class="text-center">Transport</h5>
          <b-badge pill variant="primary">
            <span>LocalAddress:</span>
            <b-badge>{{ item.transport.local_address }}</b-badge>
          </b-badge>
          <h5 class="text-center">Timers</h5>
          <b-badge pill variant="primary">
            <span>Connect Retry:</span>
            <b-badge>{{ item.timers.config.connect_retry }}</b-badge>
          </b-badge>
          <b-badge pill variant="primary">
            <span>Hold Time:</span>
            <b-badge>{{ item.timers.config.hold_time }}</b-badge>
          </b-badge>
          <b-badge pill variant="primary">
            <span>Keep Alive Interval:</span>
            <b-badge>{{ item.timers.config.keepalive_interval }}</b-badge>
          </b-badge>
          <b-badge pill variant="primary">
            <span>Negotiated Hold Time:</span>
            <b-badge>{{ item.timers.state.negotiated_hold_time }}</b-badge>
          </b-badge>
        </div>
      </b-card>
      <b-card v-else>
        <b-badge
          pill
          variant="primary"
          v-for="(value, key, index) in modalData"
          :key="index"
        >
          <span>{{ key }}:</span>
          <b-badge>{{ value }}</b-badge>
        </b-badge>
      </b-card>
    </b-modal>
  </div>
</template>
<script>
import FlowSpecService from "../../services/flow-spec.service";
import Spinner from "../../components/spinner/spinner.vue";
import { getFullFormatDateFromISOString } from "../../helpers/index";
import { notify } from "../../helpers/index";
import "vue3-toastify/dist/index.css";

export default {
  name: "FlowSpec",
  components: {
    Spinner,
  },
  data() {
    return {
      q: "",
      isLoading: false,
      specRules: [],
      currentPage: 1,
      perPage: 6,
      total: 0,
      bgpStatus: false,
      showModal: false,
      modalData: "",
      peers: [],
      isWholeBGP: false,
      fields: [
        { key: "id", label: "ID" },
        { key: "uuid", label: "UUID" },
        { key: "details", label: "Details" },
        { key: "status", label: "Status" },
        { key: "destinationPrefix", label: "Destination Prefix" },
        { key: "sourcePrefix", label: "Source Prefix" },
        { key: "ipProtocol", label: "IP Protocol" },
        { key: "sourcePort", label: "Source Port" },
        { key: "destinationPort", label: "Destination Port" },
        { key: "ICMPType", label: "ICMP Type" },
        { key: "ICMPCode", label: "ICMP Code" },
        { key: "TCPflags", label: "TCP Flags" },
        { key: "packetLength", label: "Packet Length" },
        { key: "DSCP", label: "DSCP" },
        { key: "FREncoding", label: "FR Encoding" },
        { key: "actions", label: "Actions", tdClass: "full" },
      ],
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  watch: {
    currentPage(newPage) {
      this.loadFlowSpec(newPage);
    },
  },
  mounted() {
    this.isLoading = true;
    this.getBGPServerStatus();
    this.loadFlowSpec();
  },
  methods: {
    async loadFlowSpec(page = this.currentPage) {
      const url = "flowspec/all";
      const response = await FlowSpecService.getFlowSpecRules(url, {
        currentPage: page,
        perPage: this.perPage,
        text: this.q,
      });
      if (response) {
        this.specRules = response.data.specRules;
        for (let i = 0; i < this.specRules?.length; i++){
          if (this.specRules[i]?.ipProtocol?.toLowerCase() == "unknown") {
            this.specRules[i].ipProtocol = "Any";
          }
          this.specRules[i].status = this.specRules[i]?.status?.charAt(0).toUpperCase() + this.specRules[i]?.status?.slice(1);
        }
        
        this.total = response.data.total;
        this.isLoading = false;
      }
    },
    async getBGPServerStatus() {
      const url = "flowspec/bgp";
      this.isLoading = true;
      const response = await FlowSpecService.getBGPServerStatus(url);
      if (response) {
        if ("bgp" in response.data) {
          this.bgpStatus = true;
          this.peers = response.data.peers;
        }
        this.isLoading = false;
      }
    },
    async del(item) {
      try {
        const url = "flowspec/delete/" + item.id;
        this.isLoading = true;
        const response = await FlowSpecService.removeFlowSpecRule(url);
        if (response.status === 200) {
          this.loadFlowSpec();
          this.isLoading = false;
          notify("Manual Rule deleted successfully")
        }
        else{
          notify("Manual Rule not deleted", "error")
        }
      }
      catch{
        notify("Manual Rule not deleted", "error")
      }
      
    },
    handleSearchChange() {
      this.loadFlowSpec();
    },
    async handleRateLimitChange(item) {
      const url = "flowspec/update/" + item.id;
      await FlowSpecService.updateFlowSpecRules(url, item);
    },
    async block(item) {
      item.is_blocked = true;
      this.isLoading = true;
      const url = "flowspec/update/" + item.id;
      await FlowSpecService.updateFlowSpecRules(url, item);
      this.isLoading = false;
    },
    async unblock(item) {
      item.is_blocked = false;
      this.isLoading = true;
      const url = "flowspec/update/" + item.id;
      await FlowSpecService.updateFlowSpecRules(url, item);
      this.isLoading = false;
    },
    viewNeighhours() {
      this.isWholeBGP = true;
      this.showModal = !this.showModal;
      this.modalData = this.peers;
    },
    showDetail(data) {
      let temp = data.item;
      this.isWholeBGP = false;
      this.showModal = !this.showModal;
      delete temp.details;
      temp.createdAt = getFullFormatDateFromISOString(temp.createdAt);
      temp.updatedAt = getFullFormatDateFromISOString(temp.updatedAt);
      this.modalData = temp;
    },
  },
};
</script>
<style scoped>
.rules-table {
  max-width: 100vw;
  overflow: auto;
}
.online {
  height: 25px;
  width: 25px;
  background-color: green;
  border-radius: 50%;
  display: inline-block;
}
.offline {
  height: 25px;
  width: 25px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
}
</style>
